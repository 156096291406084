<template>
  <div
    id="mention_legales_cristal_commandes_fr"
    class="text-regular"
  >
    <div class="bloc">
      <h2 class="s4 light">
        1&nbsp;: Éditeur du site et Directeur de la Publication
      </h2>
      <p>
        Le site
        <a
          :href="urlCristalCommandes"
          target="_blank"
          rel="noreferrer noopener"
        >
          {{ urlCristalCommandes }}
        </a>
        est édité par la SARL Artemis, nom commercial LDE au capital de 500&nbsp;000,00&nbsp;€,
        immatriculée au Registre du Commerce et des Sociétés de Strasbourg sous le numéro 395 135 999, dont le
        siège social est situé au 165, rue du Château 67230 WESTHOUSE.
      </p>
      <address>
        Téléphone&nbsp;: 03 88 39 17 07<br />
        Fax&nbsp;: 03 88 39 04 98<br />
        Adresse courriel&nbsp;:
        <a :href="`mailto:${mailLdeFr}`">{{ mailLdeFr }}</a>
        <br />
        Le directeur de publication est Frédéric FRITSCH, Directeur.<br />
        Le responsable du site est Pierre Schmitt, Directeur technique et innovation.
      </address>
    </div>

    <div class="bloc">
      <h2 class="s4 light">
        2&nbsp;: Hébergement du Site
      </h2>
      <p>
        Le Site est hébergé par la société française OVH dont le siège est à Roubaix, France, et plus précisément
        au 2 rue Kellermann 59100 Roubaix 1007, SAS au capital de 10&nbsp;069 020&nbsp;€, immatriculée
        au Registre du Commerce et des Sociétés de Lille Métropole sous le numéro 424 761 419 00045.
      </p>
      <p>
        Téléphone&nbsp;: +33 9 72 10 10 07<br />
        Site internet OVH&nbsp;:
        <a
          href="https://help.ovhcloud.com/fr/"
          target="_blank"
          rel="noreferrer noopener"
        >
          https://help.ovhcloud.com/fr/
        </a>
      </p>
    </div>

    <div class="bloc">
      <h2 class="s4 light">
        3&nbsp;: Réalisation
      </h2>
      <address>
        <span class="underline">Design UX/UI</span>&nbsp;:<br />
        ADVISA, Webdesign<br />
        32, rue du Bassin d'Austerlitz<br />
        67100 Strasbourg<br />
        <a
          href="https://advisa.fr/"
          target="_blank"
          rel="noreferrer noopener"
        >
          https://advisa.fr/
        </a>
        <br />
        Fred Tinsel<br />
        <a href="mailto:ftinsel@advisa.fr">ftinsel@advisa.fr</a>
      </address>

      <address>
        <span class="underline">Développement</span>&nbsp;:<br />
        LDE<br />
        1, rue de Kyoto<br />
        67150 ERSTEIN<br />
        <a
          :href="urlLde"
          target="_blank"
          rel="noreferrer noopener"
        >
          {{ urlLde }}
        </a>
      </address>
    </div>
  </div>
</template>

<script>
import config from "@/config";

/**
 * Affiche la page des mentions légales de Cristal Commandes en français.
 */
export default {
  name: "MentionsLegalesCristalCommandesFr",
  computed: {
    mailLdeFr() {
      return config.mailsFr.lde;
    },
    urlLde() {
      return config.urlFr.lde;
    },
    urlCristalCommandes() {
      return config.urlFr.cristalCommandes;
    },
  },
};
</script>

<style lang="scss"></style>
