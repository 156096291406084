<template>
  <div id="mentions_legales_cristal_commandes">
    <MentionsLegalesCristalCommandesDe v-if="country === 'de'" />
    <MentionsLegalesCristalCommandesFr v-else />
  </div>
</template>

<script>
import MentionsLegalesCristalCommandesFr from "@/components/legal/cc/MentionsLegalesCristalCommandesFr.vue";
import MentionsLegalesCristalCommandesDe from "@/components/legal/cc/MentionsLegalesCristalCommandesDe.vue";

import { mapGetters } from "vuex";

/**
 * Affiche les mentions légales de Cristal Commandes dans la bonne langue.
 */
export default {
  name: "MentionsLegalesCristalCommandes",
  components: {
    MentionsLegalesCristalCommandesFr,
    MentionsLegalesCristalCommandesDe,
  },
  computed: {
    ...mapGetters(["country"]),
  },
};
</script>

<style lang="scss">
@use "@/assets/styles/views/_legal.scss";
</style>
